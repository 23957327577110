<template>
  <div class="d-flex justify-content-center" style="margin-top: 25%">
    <MDBSpinner color="primary" style="width: 4rem; height: 4rem" />
  </div>
</template>

<script>
import { MDBSpinner } from 'mdb-vue-ui-kit';

export default {
  components: {
    MDBSpinner,
  },
};
</script>
