import { createApp } from 'vue';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import 'mdb-vue-ui-kit/css/mdb.min.css';
import 'quill/dist/quill.snow.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'animate.css';
import './assets/css/cursor.css';
import './assets/css/my.css';
import App from './App.vue';
import router from './router';
// import i18n from './locales';
// import store from './store';

createApp(App)
  .use(router)
  .use(Toast, {
    transition: 'Vue-Toastification__bounce',
    maxToasts: 60,
    newestOnTop: true,
    position: 'top-right',
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: 'button',
    icon: true,
    rtl: false,
  })
  .mount('#app');
