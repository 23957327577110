<template>
  <MDBNavbar expand="lg" light bg="light" container>
    <MDBNavbarBrand href="#" class="text-primary">BlueTester</MDBNavbarBrand>
    <MDBNavbarToggler
      @click="collapse1 = !collapse1"
      target="#navbarSupportedContent"
    ></MDBNavbarToggler>
    <MDBCollapse v-model="collapse1" id="navbarSupportedContent">
      <MDBNavbarNav class="mb-2 mb-lg-0"
        ><ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <RouterLink class="nav-link" to="/dashboard">Home</RouterLink>
          </li>
          <li class="nav-item">
            <RouterLink class="nav-link" to="/tests">Tests</RouterLink>
          </li>
          <li class="nav-item">
            <RouterLink class="nav-link" to="/login" @click="logout">Log out</RouterLink>
          </li>
        </ul>
      </MDBNavbarNav>
    </MDBCollapse>
  </MDBNavbar>
</template>

<style scoped>
.nav-link:hover {
  color: var(--mdb-primary);
}
</style>

<script>
import {
  MDBNavbar,
  MDBNavbarToggler,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBCollapse,
} from 'mdb-vue-ui-kit';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
  components: {
    MDBNavbar,
    MDBNavbarToggler,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBCollapse,
  },
  setup() {
    const router = useRouter();
    const collapse1 = ref(false);
    const dropdown1 = ref(false);
    const logout = () => {
      localStorage.removeItem('token');
      router.push('/login');
    };
    return {
      collapse1,
      dropdown1,
      logout,
    };
  },
};
</script>
