import { createRouter, createWebHistory } from 'vue-router';

import HomeView from '../views/HomeView.vue';
import AuthPage from '../views/AuthPage.vue';
import DashBoard from '../views/DashBoard.vue';
import TestsPage from '../views/TestsPage.vue';
import ViewTest from '../views/ViewTest.vue';
import TesterV2 from '../views/TesterV2.vue';
import TesterV1 from '../views/TesterV1.vue';
import FullResults from '../views/FullResults.vue';

const routes = [
  {
    path: '/',
    component: HomeView,
    meta: {
      title: 'BlueTester - Home',
    },
  },
  {
    path: '/login',
    component: AuthPage,
    meta: {
      title: 'BlueTester - Auth',
    },
  },
  {
    path: '/dashboard',
    component: DashBoard,
    meta: {
      title: 'BlueTester - Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/tests',
    component: TestsPage,
    meta: {
      title: 'BlueTester - Tests',
      requiresAuth: true,
    },
  },
  {
    path: '/tests/:id',
    component: ViewTest,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tests/:id/v2',
    component: TesterV2,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tests/:id/v1',
    component: TesterV1,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tests/:id/results',
    component: FullResults,
  },
  {
    path: '/profile/:username',
    component: DashBoard,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.meta.title !== undefined) {
    document.title = to.meta.title;
  }
  if (to.meta.requiresAuth) {
    if (localStorage.getItem('token') !== null) {
      next();
    } else {
      next('/login');
    }
  } else {
    next();
  }
});
export default router;
