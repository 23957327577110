<template>
  <Sidenav :active="1" />
  <MDBModal id="modal" tabindex="-1" labelledby="label" v-model="modal">
    <MDBModalHeader>
      <MDBModalTitle id="label">Apply test</MDBModalTitle>
    </MDBModalHeader>
    <MDBModalBody>
      <p>Please enter test key:</p>
      <MDBInput label="Key" wrapper-class="mb-3" v-model="key" />
      <p><i>You can get test key from test creator or your teacher!</i></p>
    </MDBModalBody>
    <MDBModalFooter>
      <MDBBtn color="secondary" @click="modal = false">Cancel</MDBBtn>
      <MDBBtn color="primary" @click="submit">Submit</MDBBtn>
    </MDBModalFooter>
  </MDBModal>
  <MDBModal size="xl" id="modal2" tabindex="-1" labelledby="label2" v-model="info">
    <MDBModalHeader>
      <MDBModalTitle id="label2">Info</MDBModalTitle>
    </MDBModalHeader>
    <MDBModalBody>
      <MDBAccordion v-model="activeItem">
        <MDBAccordionItem
          icon="fas fa-question-circle fa-sm me-2 opacity-70"
          headerTitle="Can I exit exam when it started?"
          collapseId="c1"
        >
          <p v-if="JSON.parse(data.test.config).hard">If you exit test autosubmit</p>
          <p v-else>Yes you can!</p>
        </MDBAccordionItem>
      </MDBAccordion>
    </MDBModalBody>
    <MDBModalFooter>
      <MDBBtn color="secondary" @click="info = false">Cancel</MDBBtn>
    </MDBModalFooter>
  </MDBModal>
  <div class="container-fluid main-content mt-5">
    <Loader v-if="status === 0" />
    <div class="card mt-5" v-if="status === 1">
      <div class="card-body">
        <h3 class="card-title">
          {{ data.test.title }}
          <MDBBtn color="primary" floating @click="info = true"
            ><i class="fas fa-question"></i
          ></MDBBtn>
        </h3>
        <p class="card-text" v-html="data.test.about.replace('\n', '<br/>')"></p>
        <table class="table table-bordered">
          <tbody>
            <tr>
              <th>Number of tests:</th>
              <td>{{ data.test.tests }} ta</td>
            </tr>
            <tr>
              <th>Max score:</th>
              <th>{{ data.test['max_score'] }} ball</th>
            </tr>
            <tr>
              <th>Creator:</th>
              <th>{{ data.test.creator.fullName }}</th>
            </tr>
            <tr>
              <th>Status:</th>
              <th :class="getStatus(data.test)[1]">{{ getStatus(data.test)[0] }}</th>
            </tr>
            <tr>
              <th>Starts at:</th>
              <th>{{ new Date(data.test.start * 1000).toLocaleString() }}</th>
            </tr>
            <tr>
              <th>Now:</th>
              <th>{{ String(now.toLocaleString()) }}</th>
            </tr>
            <tr>
              <th>Finishes At:</th>
              <th>{{ new Date(data.test.finish * 1000).toLocaleString() }}</th>
            </tr>
            <tr>
              <th>Duration:</th>
              <th>{{ s2hm(data.test.duration) }}</th>
            </tr>
          </tbody>
        </table>
        <div v-if="data.test && data.user">
          <MDBBtn
            color="danger"
            v-if="data.user.status === 'applied' && data.test.start > Math.floor(new Date() / 1000)"
            @click="unApply"
            >Cancel</MDBBtn
          >
          <!-- eslint-disable -->
          <MDBBtn
            color="primary"
            v-else-if="
              data.test.finish > Math.floor(new Date() / 1000) && data.user.status === 'applied'
            "
            @click="start"
            >Start</MDBBtn
          >
          <MDBBtn
            color="primary"
            v-else-if="
              data.user.status === 'no' && data.test.finish > Math.floor(new Date() / 1000)
            "
            @click="modal = true"
            >Apply</MDBBtn
          >
          <MDBBtn
            v-else-if="data.user.status === 'started' || data.user.status === 'finished'"
            color="success"
            @click="results"
            >Results</MDBBtn
          >
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.table {
  max-width: 600px;
}

tr {
  font-size: 17px;
}
</style>
<script>
// eslint-disable-next-line
import { onMounted, reactive, ref, onBeforeUnmount, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Swal from 'sweetalert2';
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBAccordion,
  MDBAccordionItem,
} from 'mdb-vue-ui-kit';
import { useToast } from 'vue-toastification';
import Sidenav from '../components/NewSidenav.vue';
import Loader from '../components/MySpinner.vue';
import axios from '../axios';

export default {
  components: {
    Sidenav,
    Loader,
    MDBBtn,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBInput,
    MDBAccordion,
    MDBAccordionItem,
  },
  setup() {
    const toast = useToast();
    const data = ref({ title: 'Title' });
    const route = useRoute();
    const router = useRouter();
    const now = ref(new Date());
    const modal = ref(false);
    const status = ref(0);
    const key = ref('');
    const info = ref(false);
    const time = ref(null);
    const activeItem = ref('');
    const s2hm = (d) => {
      const h = Math.floor(d / 3600);
      const m = Math.floor((d % 3600) / 60);
      const s = Math.floor((d % 3600) % 60);
      const hDisplay = h > 0 ? h + (h === 1 ? ' hour ' : ' hours ') : '';
      const mDisplay = m > 0 ? m + (m === 1 ? ' minute' : ' minutes') : '';
      const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
      return hDisplay + mDisplay + sDisplay;
    };
    const isInProgress = () => Math.floor(now.value / 1000) < data.value.test.finish;
    const getStatus = (x) => {
      if (x.start >= Math.floor(Date.now() / 1000)) {
        return ['Not started', 'text-success'];
      }
      if (x.start <= Math.floor(Date.now() / 1000) && x.finish > Math.floor(Date.now() / 1000)) {
        return ['In progress', 'text-primary'];
      }
      return ['Finished', 'text-danger'];
    };
    const submit = () => {
      if (key.value === '') {
        toast.error('Please enter key!');
      } else {
        status.value = 0;
        const header = {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        };
        axios
          .post(`/api/tests/${route.params.id}/apply`, { key: key.value }, header)
          .then(() => {
            data.value.user.status = 'applied';
            toast.success('Applied');
            modal.value = false;
            status.value = 1;
          }, header)
          .catch((e) => {
            status.value = 1;
            if (e.response) {
              if (e.response.status === 404) {
                router.push('/tests');
              } else if (e.response.status === 401) {
                toast.error('Unauthorizated!');
              } else if (e.response.status === 403) {
                toast.error('Invalid key!');
              } else {
                toast.error('Invalid error!');
              }
            } else {
              toast.error('Invalid error!');
            }
          });
      }
    };
    const start = () => {
      const v = JSON.parse(data.value.test.config).onebox;
      if (v) {
        router.push(`/tests/${route.params.id}/v2`);
      } else {
        router.push(`/tests/${route.params.id}/v1`);
      }
    };
    onBeforeUnmount(() => {
      clearInterval(time.value);
    });
    const unApply = () => {
      status.value = 0;
      axios
        .post(`/api/tests/${route.params.id}/unApply`, null, {
          headers: { Authorization: localStorage.getItem('token') },
        })
        .then(() => {
          status.value = 1;
          data.value.user.status = 'no';
          toast.success('Application canceled!');
        })
        .catch(() => {
          status.value = 1;
          toast.error('Request failed!');
        });
    };
    const loadData = () => {
      status.value = 0;
      axios
        .get(`/api/tests/${route.params.id}?key=${route.query.key}`, {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        })
        .then((r) => {
          document.title = `BlueTester - ${r.data.test.title}`;
          data.value = r.data;
          status.value = 1;
          time.value = setInterval(() => {
            now.value = new Date();
          }, 1000);
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 403) {
              Swal.fire({
                title: 'Access denied!',
                text: 'Please check url!',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok!',
              }).then(() => {
                router.push('/tests');
              });
            } else if (e.response.status === 404) {
              Swal.fire({
                title: 'Test not found!',
                text: 'Please check url!',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok!',
              }).then(() => {
                router.push('/tests');
              });
            } else {
              Swal.fire({
                title: 'Something went wrong!',
                text: 'Please try again later.',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok!',
              }).then(() => {
                router.push('/tests');
              });
            }
          } else {
            Swal.fire({
              title: 'Something went wrong!',
              text: 'Please try again later.',
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok!',
            }).then(() => {
              router.push('/tests');
            });
          }
        });
    };
    const results = () => {
      router.push(`/tests/${route.params.id}/results`);
    };
    onMounted(() => {
      document.title = 'BlueTester - Loading';
      watch(info, (x) => {
        if (!x) {
          activeItem.value = '';
        }
      });
      loadData();
    });
    return {
      data,
      status,
      modal,
      activeItem,
      now,
      info,
      key,
      time,
      s2hm,
      isInProgress,
      getStatus,
      submit,
      loadData,
      start,
      unApply,
      results,
    };
  },
};
</script>
