<template>
  <Sidebar :active="0" />
  <div class="container-fluid main-content mt-1">
    <div class="card">
      <div class="card-body">
        <h2 class="card-title">
          Welcome to
          <span class="text-primary">BlueTester</span>
        </h2>
        <p class="card-text">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus dolorem minus
          laboriosam. Non adipisci est quae voluptas eligendi facere voluptate, animi similique
          saepe doloribus ducimus nostrum molestias corrupti aperiam, et itaque, alias odio esse
          totam provident laboriosam? Velit odit magnam ipsa magni exercitationem atque quas,
          delectus labore quasi aspernatur iusto maiores explicabo laudantium nihil, adipisci, ab
          ullam aliquid incidunt libero amet! Delectus dolore praesentium, architecto enim
          aspernatur aliquid dicta omnis!
        </p>
      </div>
    </div>
    <div class="container-fluid mt-5 mb-5">
      <!-- Section: Features -->
      <!-- <section class="text-center" id="features">
        <h3><strong>Features</strong></h3>
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <i class="fas fa-tachometer-alt fa-3x text-primary"></i>
            <h5 class="my-4">Fast</h5>
            <p class="mb-md-3">Blazing fast load times is my highest priority</p>
          </div>
          <div class="col-lg-3 col-md-6">
            <i class="fas fa-mobile-alt fa-3x text-primary"></i>
            <h5 class="my-4">Responsive</h5>
            <p class="mb-md-3">
              My layouts will look perfect on any device, regardless of screen size.
            </p>
          </div>
          <div class="col-lg-3 col-md-6">
            <i class="far fa-lightbulb fa-3x text-primary"></i>
            <h5 class="my-4">Intuitive</h5>
            <p class="mb-md-3">Easy to use, intuitive UX/UI out of the box.</p>
          </div>
          <div class="col-lg-3 col-md-6">
            <i class="fas fa-rocket fa-3x text-primary"></i>
            <h5 class="my-4">Dynamic</h5>
            <p class="mb-md-3">Website don't have to be boring. I love to give them a life.</p>
          </div>
        </div>
      </section> -->
    </div>
    <div class="row mt-5">
      <h3 class="text-center mb-1"><strong>Charts</strong></h3>
      <div class="col-md-4">
        <canvas class="mb-4" id="chart"></canvas>
      </div>
      <div class="col-md-4">
        <canvas class="mb-4" id="chart2"></canvas>
      </div>
      <div class="col-md-4">
        <canvas class="mb-4" id="chart3"></canvas>
      </div>
    </div>
  </div>
</template>
<style scoped>
.fas,
.far {
  transition: all 0.5s;
}
.fas:hover,
.far:hover {
  color: var(--mdb-success) !important;
}
</style>
<script>
import { reactive, onMounted } from 'vue';
/*eslint-disable*/
import Chart from 'chart.js/auto';
import Sidebar from '../components/NewSidenav.vue';

export default {
  components: {
    Sidebar,
  },
  setup() {
    const stat = reactive({
      users: 135,
      tests: 235,
      attemps: 135,
    });
    onMounted(() => {
      // eslint-disable-next-line
      new Chart(document.getElementById('chart').getContext('2d'), {
        type: 'line',
        data: {
          labels: Array.from({ length: 15 }).fill(''),
          datasets: [
            {
              label: 'Users',
              data: (() => {
                const x = [];
                for (let i = 0; i < 15; i++) {
                  x.push(Math.floor(Math.random() * 1500) + Math.floor(Math.random() * 150));
                }
                return x;
              })(),
              borderColor: '#e4a11b',
              backgroundColor: 'white',
              tension: 0.1,
            },
          ],
        },
      });
      new Chart(document.getElementById('chart2').getContext('2d'), {
        type: 'line',
        data: {
          labels: Array.from({ length: 15 }).fill(''),
          datasets: [
            {
              label: 'Results',
              data: (() => {
                const x = [];
                for (let i = 0; i < 15; i++) {
                  x.push(Math.floor(Math.random() * 1500) + Math.floor(Math.random() * 150));
                }
                return x;
              })(),
              borderColor: '#14a44d',
              tension: 0.1,
            },
          ],
        },
      });
      new Chart(document.getElementById('chart3').getContext('2d'), {
        type: 'line',
        data: {
          labels: Array.from({ length: 15 }).fill(''),
          datasets: [
            {
              label: 'Tests',
              data: (() => {
                const x = [];
                for (let i = 0; i < 15; i++) {
                  x.push(Math.floor(Math.random() * 1500) + Math.floor(Math.random() * 150));
                }
                return x;
              })(),
              borderColor: '#3b71ca',
              tension: 0.1,
            },
          ],
        },
      });
    });
    return {
      stat,
    };
  },
};
</script>
