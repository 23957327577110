<template>
  <div>
    <div class="sidebar" v-if="width > 800">
      <div class="logo">
        <h3 class="text-primary text-center m-3">BlueTester</h3>
      </div>
      <hr />
      <MDBListGroup light>
        <MDBListGroupItem
          v-for="(item, id) in items"
          tag="a"
          href="#"
          ripple
          noBorder
          spacing
          action
          small
          :active="active == id"
          @click="goto(item)"
          class="py-2 sidenav-link"
          :key="id"
          style="border-radius: 0 !important"
        >
          <i class="fa" :class="item.icon"></i>{{ item.title }}
        </MDBListGroupItem>
      </MDBListGroup>
    </div>
    <div v-else>
      <Navbar />
    </div>
  </div>
</template>

<style scoped>
.sidebar {
  position: fixed;
  display: block;
  top: 0;
  bottom: 0;
  left: -250;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgba(241, 222, 222, 0.05);
  width: 250px;
  z-index: 600;
  background-color: white;
  transition: all 1s !important;
  border-right: 1px solid rgba(0, 0, 255, 0.1);
}
.sidebar i {
  margin-right: 10px;
  margin-left: 20px;
}

.mr-250 {
  margin-left: 250px !important;
}

@media screen and (min-width: 600px) {
  .sidenav-toggler {
    display: none;
  }
}
</style>

<script>
import { MDBListGroup, MDBListGroupItem } from 'mdb-vue-ui-kit';
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import Navbar from './MyNavbar2.vue';

export default {
  components: {
    MDBListGroup,
    MDBListGroupItem,
    Navbar,
  },
  props: {
    active: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const router = useRouter();
    const width = ref(window.innerWidth);
    onMounted(() => {
      window.addEventListener('resize', () => {
        width.value = window.innerWidth;
      });
    });
    const goto = (x) => {
      if (x.role === 'exit') {
        localStorage.removeItem('token');
      }
      router.push(x.to);
    };
    const sidenav = ref(true);
    const items = ref([
      {
        title: 'Home',
        icon: 'fa-home',
        to: '/dashboard',
      },
      {
        title: 'Tests',
        icon: 'fa-check',
        to: '/tests',
      },
      {
        title: 'Log out',
        icon: 'fa-solid fa-right-from-bracket',
        role: 'exit',
        to: '/login',
      },
    ]);
    return {
      items,
      sidenav,
      goto,
      width,
    };
  },
};
</script>
