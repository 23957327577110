<template>
  <Navbar />
  <div class="container animate__animated animate__fadeInDownBig">
    <div class="card">
      <div class="card-body">
        <MDBTabs v-model="tab">
          <MDBTabNav pills justify tabsClasses="mb-3">
            <MDBTabItem tabId="tab1" href="tab1">Sign In</MDBTabItem>
            <MDBTabItem tabId="tab2" href="tab2">Sign Up</MDBTabItem>
          </MDBTabNav>
          <MDBTabContent>
            <MDBTabPane tabId="tab1">
              <form autocomplete="off" v-if="tab === 'tab1'">
                <MDBInput
                  type="text"
                  label="Username"
                  v-model="login.username"
                  wrapperClass="mb-4 mt-1"
                />
                <MDBInput
                  type="password"
                  label="Password"
                  v-model="login.password"
                  wrapperClass="mb-4 mt-1"
                />
                <MDBBtn
                  @click="submitLogin"
                  load
                  color="primary"
                  class="mb-4 mt-1"
                  :disabled="status === 0"
                >
                  <div v-if="status === 1">Submit</div>
                  <div v-else>
                    <MDBSpinner :class="{ 'not-allowed': status === 0 }" tag="span" size="sm" />
                  </div>
                </MDBBtn>
              </form>
            </MDBTabPane>
            <MDBTabPane tabId="tab2">
              <form autocomplete="off" @submit.prevent="submitSign" v-if="tab === 'tab2'">
                <div class="row">
                  <div class="col">
                    <MDBInput
                      type="text"
                      label="First name"
                      v-model="sign.firstName"
                      wrapper-class="mb-4"
                      minLength="3"
                      maxLength="20"
                      invalidFeedback="Invalid input!"
                      validFeedback="Looks good!"
                      required
                    />
                  </div>
                  <div class="col">
                    <MDBInput
                      type="text"
                      label="Last name"
                      wrapper-class="mb-4 "
                      v-model="sign.lastName"
                      minLength="5"
                      maxLength="30"
                      invalidFeedback="Invalid input!"
                      validFeedback="Looks good!"
                      required
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <MDBInput
                      type="email"
                      label="Email"
                      v-model="sign.email"
                      wrapper-class="mb-4"
                      invalidFeedback="Invalid input!"
                      validFeedback="Looks good!"
                      required
                    />
                  </div>
                  <div class="col">
                    <MDBInput
                      type="tel"
                      label="Phone"
                      v-model="sign.phone"
                      wrapper-class="mb-4"
                      pattern="[+]{1}[998]{3}[-\s\./0-9]*"
                      invalidFeedback="Invalid input!"
                      validFeedback="Looks good!"
                      required
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <MDBInput
                      type="date"
                      label="Birthday"
                      v-model="sign.birthday"
                      wrapper-class="mb-4"
                      invalidFeedback="Invalid input!"
                      validFeedback="Looks good!"
                      required
                    />
                  </div>
                  <div class="col">
                    <MDBInput
                      type="text"
                      label="Username"
                      v-model="sign.username"
                      wrapper-class="mb-4"
                      minLength="5"
                      maxLength="30"
                      invalidFeedback="Invalid input!"
                      validFeedback="Looks good!"
                      required
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <MDBInput
                      :type="showPassword ? 'text' : 'password'"
                      label="Password"
                      v-model="sign.password"
                      wrapper-class="mb-"
                      invalidFeedback="Invalid input!"
                      validFeedback="Looks good!"
                      required
                    />
                  </div>
                  <div class="col">
                    <MDBInput
                      :type="showPassword ? 'text' : 'password'"
                      label="Password"
                      v-model="sign.repassword"
                      wrapper-class="mb-4"
                      required
                    />
                  </div>
                </div>
                <MDBCheckbox label="Show password" v-model="showPassword" />
                <MDBCheckbox
                  label="By creating an account you agree our Privacy policy and Cookie policy!"
                  v-model="legacy"
                  wrapper-class="mb-4"
                  required
                />
                <MDBBtn color="primary" type="submit" :disabled="status === 0">
                  <div v-if="status === 0">
                    <MDBSpinner tag="span" size="sm" />
                  </div>
                  <div v-else>Submit</div>
                </MDBBtn>
              </form>
            </MDBTabPane>
          </MDBTabContent>
        </MDBTabs>
      </div>
    </div>
  </div>
</template>
<style>
.container {
  margin-top: 100px;
  max-width: 700px;
}
</style>
<script>
import {
  MDBInput,
  MDBBtn,
  MDBTabs,
  MDBTabNav,
  MDBTabContent,
  MDBTabItem,
  MDBSpinner,
  MDBTabPane,
  MDBCheckbox,
} from 'mdb-vue-ui-kit';
import { computed, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import Navbar from '../components/MyNavbar.vue';
import axios from '../axios';

export default {
  components: {
    MDBInput,
    MDBBtn,
    MDBTabs,
    MDBTabNav,
    MDBTabContent,
    MDBTabItem,
    MDBTabPane,
    MDBSpinner,
    Navbar,
    MDBCheckbox,
  },
  setup() {
    const toast = useToast();
    const router = useRouter();
    const status = ref(1);
    const submitted = ref(false);
    const tab = ref('tab1');
    const showPassword = ref(false);
    const legacy = ref(true);
    const signSubmitted = ref(false);
    const login = reactive({
      username: '',
      password: '',
    });
    const sign = reactive({
      firstName: '',
      lastName: '',
      email: '',
      birthday: '',
      phone: '',
      username: '',
      password: '',
      repassword: '',
    });
    const inRange = (a, x, y) => a.length > x && a.length < y;
    const validPassword = computed(() => {
      if (
        // eslint-disable-next-line
        sign.password.length >= 8 &&
        // eslint-disable-next-line
        sign.repassword.length >= 8 &&
        // eslint-disable-next-line
        sign.password === sign.repassword
      ) {
        return true;
      }
      return false;
    });
    const submitLogin = () => {
      status.value = 0;
      axios
        .post('/api/signIn', login)
        .then((r) => {
          status.value = 1;
          localStorage.setItem('token', r.data.token);
          router.push('/dashboard');
          toast.success('Welcome to BlueTester!');
        })
        .catch((e) => {
          status.value = 1;
          if (e.response) {
            if (e.response.status === 401) {
              toast.error('Invalid credentials!');
              return;
            }
          }
          toast.error('Something went wrong!');
        });
    };
    const submitSign = (event) => {
      if (event.target.checkValidity()) {
        event.preventDefault();
        if (sign.password !== sign.repassword) {
          toast.error('Passwords don`t match!');
          return;
        }
        status.value = 0;
        axios
          .post('/api/signUp', sign)
          .then((r) => {
            status.value = 1;
            if (r.data.token) {
              // save data
              localStorage.setItem('token', r.data.token);
              router.push('/dashboard');
              toast.success('Sign Up successfully!');
            } else {
              toast.error('Invalid error occured!');
            }
          })
          .catch((e) => {
            status.value = 1;
            if (e.response) {
              if (e.response.status === 400) {
                toast.clear();
                if (e.response.data.value === 'username') {
                  toast.error('Username is already used!', { timeout: 10000 });
                } else if (e.response.data.value === 'email') {
                  toast.error('Email is already used!', { timeout: 10000 });
                } else if (e.response.data.value === 'phone_number') {
                  toast.error('Phone number is already used!', { timeout: 10000 });
                } else {
                  toast.error(e.response.data.value, { timeout: 10000 });
                }
              }
            } else {
              toast.clear();
              toast.error('Invalid error occured!');
            }
          });
      }
    };
    return {
      tab,
      login,
      status,
      sign,
      submitLogin,
      inRange,
      submitted,
      signSubmitted,
      submitSign,
      showPassword,
      legacy,
      validPassword,
    };
  },
};
</script>
