<template>
  <div class="ow-hidden">
    <MDBNavbar expand="lg" light bg="light" container>
      <MDBNavbarBrand href="#">
        <div class="fs-bold h4 text-primary">BlueTester</div>
      </MDBNavbarBrand>
      <MDBNavbarToggler
        @click="collapse1 = !collapse1"
        target="#navbarSupportedContent"
      ></MDBNavbarToggler>
      <MDBCollapse v-model="collapse1" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <RouterLink class="nav-link" to="/">Home</RouterLink>
          </li>
          <li class="nav-item">
            <RouterLink class="nav-link" to="/dashboard">Dashboard</RouterLink>
          </li>
        </ul>
        <div class="d-flex input-group w-auto nothing">
          <MDBNavbarNav class="mb-2 mb-lg-0">
            <MDBDropdown class="nav-item" v-model="profile" v-if="isLogged">
              <MDBDropdownToggle tag="a" class="nav-link" @click="profile = !profile">
                <i class="fa-solid fa-user"></i>
              </MDBDropdownToggle>
              <MDBDropdownMenu>
                <MDBDropdownItem href="#">My profile</MDBDropdownItem>
                <MDBDropdownItem href="#">Settings</MDBDropdownItem>
                <MDBDropdownItem href="#">Logout</MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
            <MDBNavbarItem to="/login" v-else>
              <i class="fa-solid fa-right-to-bracket"></i>
            </MDBNavbarItem>
          </MDBNavbarNav>
        </div>
      </MDBCollapse>
    </MDBNavbar>
    <div class="mt-3 container">
      <div class="h-100 d-flex justify-content-center align-items-center">
        <h1 class="mt-4">Welcome to BlueTester!</h1>
      </div>
    </div>
  </div>
</template>
<style scoped>
.navbar-brand {
  font-weight: 500;
}
.nav-link {
  font-size: 18px;
  opacity: 1;
  transition: all 0.2s;
}
.nav-link:hover,
.nav-link:target {
  color: var(--mdb-primary);
}
</style>
<script>
import {
  MDBNavbar,
  MDBNavbarToggler,
  MDBNavbarBrand,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBNavbarItem,
  MDBNavbarNav,
} from 'mdb-vue-ui-kit';
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';

export default {
  components: {
    MDBNavbar,
    MDBNavbarToggler,
    MDBNavbarBrand,
    MDBCollapse,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownItem,
    MDBDropdownMenu,
    MDBNavbarItem,
    MDBNavbarNav,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const collapse1 = ref(false);
    const dropdown1 = ref(false);
    const profile = ref(false);
    const isLogged = ref(false);
    onMounted(() => {
      isLogged.value = localStorage.getItem('token') !== null;
    });
    const goto = (x) => {
      router.push(x);
    };
    return {
      collapse1,
      dropdown1,
      isLogged,
      goto,
      profile,
      route,
    };
  },
};
</script>
