<template>
  <Sidenav :active="1" />
  <div class="card container-fluid main-content mt-3 mb-5" v-if="data !== 'No'">
    <div class="card-body">
      <div>
        <h1 v-if="data.test" class="animate__animated animate__fadeInDown">
          Results of
          <div class="d-inline text-primary">{{ data.test.title }}</div>
        </h1>
        <div class="table-responsive">
          <table class="table table-bordered" @selectstart.prevent="" @copy.prevent="">
            <thead>
              <tr>
                <th>#</th>
                <th scope="col">Full name</th>
                <th scope="col">Score</th>
                <th scope="col">Duration</th>
                <th scope="col">Started at</th>
                <th scope="co">Finished at</th>
                <th scope="col">Percent</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(r, id) in data.results"
                :key="id"
                :class="{ 'text-danger': r.cheating !== 'no' }"
              >
                <th>{{ id + 1 }}</th>
                <td>{{ r.name }} {{ r.cheating !== 'no' ? '(Cheater)' : '' }}</td>
                <td>{{ r.score }} ball</td>
                <td>{{ s2hm(r.finish - r.start) }}</td>
                <td>{{ new Date(r.start * 1000).toLocaleString() }}</td>
                <td>{{ new Date(r.finish * 1000).toLocaleString() }}</td>
                <td>{{ r.precent }}%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <Spinner />
  </div>
</template>
<style scoped>
.table {
  border-radius: 5px !important;
  max-width: 1200px;
}
tr,
tbody tr td {
  transition: 0.1s ease-in-out;
}
th {
  font-weight: bold;
}
tbody tr td:hover {
  color: var(--mdb-primary);
  font-size: 17px;
}
</style>
<script>
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Swal from 'sweetalert2';
import axios from '../axios';
import Spinner from '../components/MySpinner.vue';
import Sidenav from '../components/NewSidenav.vue';

export default {
  components: { Sidenav, Spinner },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const data = ref([]);
    const i = ref(null);
    const s2hm = (d) => {
      const h = Math.floor(d / 3600);
      const m = Math.floor((d % 3600) / 60);
      const s = Math.floor((d % 3600) % 60);
      const hDisplay = h > 0 ? h + (h === 1 ? ' hour ' : ' hours ') : '';
      const mDisplay = m > 0 ? m + (m === 1 ? ' minute ' : ' minutes ') : '';
      const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
      return hDisplay + mDisplay + sDisplay;
    };
    const loadData = () => {
      if (route.params.id === undefined || !route.fullPath.includes('results')) {
        clearInterval(i.value);
        i.value = {};
        return;
      }
      axios
        .get(`api/tests/${route.params.id}/results`)
        .then((r) => {
          document.title = `BlueTester - Results of ${r.data.test.title}`;
          if (r.data.status === 'empty') {
            data.value = 'No';
          } else {
            data.value = r.data;
          }
        })
        .catch(() => {
          clearInterval(i.value);
          Swal.fire({
            title: 'Something went wrong!',
            text: 'Please try again!',
            icon: 'error',
            showCancelButton: true,
            confirmButtonText: 'Retry',
            confirmButtonColor: '#007BFF',
            cancelButtonText: 'Go back',
            cancelButtonColor: '#28A745',
          }).then((result) => {
            if (result.isConfirmed) {
              loadData();
            } else {
              router.push('/tests');
            }
          });
        });
    };
    onMounted(() => {
      loadData();
      document.title = 'BlueTester - Loading';
      i.value = setInterval(() => {
        loadData();
      }, 5000);
    });
    onBeforeMount(() => {
      clearInterval(i.value);
    });
    route.beforeRouteLeave = () => {
      clearInterval(i.value);
    };
    return { data, s2hm };
  },
};
</script>
