<template>
  <Loader v-if="status === 0" />
  <div class="container-fluid mt-5" v-else>
    <div class="card mb-4">
      <div class="card-body">
        <!-- eslint-disable -->
        <div class="text-center">
          <p class="h1" :class="answer == current.valid ? '' : ''">
            {{ interval.display }}
          </p>
        </div>
        <MDBProgress :height="5">
          <MDBProgressBar :value="now" striped animated />
        </MDBProgress>
      </div>
    </div>
    <div class="mx-0 mx-sm-auto">
      <div class="card">
        <div class="card-header bg-primary">
          <h5 class="card-title text-white mt-2" id="exampleModalLabel">BlueTester V2</h5>
        </div>
        <div class="modal-body">
          <div class="text-start quiz nx-2" v-html="current.question"></div>
          <hr />
          <div class="px-4">
            <p class="text-center">
              Choose your option (<strong>{{ current.score }} ball</strong>):
            </p>
          </div>
          <div class="nx-2">
            <MDBRadio
              v-for="x in current.options"
              :key="x.id"
              :label="x.value"
              v-model="answer"
              name="answer"
              :value="x.id"
            />
          </div>
        </div>
        <div class="card-footer text-end">
          <MDBBtn color="primary" :disabled="answer === ''" @click="next">{{
            id + 1 === data.questions.length ? 'Finish' : 'Next'
          }}</MDBBtn>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.quiz {
  margin: 5px 5px 5px 5px;
  margin-right: 10px;
  font-size: 21px;
}
.quiz img {
  width: 120px !important;
  max-height: 450px !important;
}
.container-fluid {
  max-width: 999px;
}
.nx-2 {
  margin-left: 10px !important;
}
</style>
<script>
// eslint-disable-next-line
import { MDBBtn, MDBProgress, MDBProgressBar, MDBRadio } from 'mdb-vue-ui-kit';
import { onMounted, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Swal from 'sweetalert2';
import axios from '../axios';
import Loader from '../components/MySpinner.vue';

export default {
  components: {
    MDBProgress,
    MDBProgressBar,
    MDBBtn,
    MDBRadio,
    Loader,
  },
  setup() {
    const now = ref(0);
    const current = ref({});
    const route = useRoute();
    const router = useRouter();
    const data = reactive({});
    const id = ref(0);
    const status = ref(0);
    const userAnswers = ref([]);
    const answer = ref('');
    const interval = reactive({ display: '', value: '', time: '' });
    const submit = () => {
      status.value = 0;
      const headers = { headers: { Authorization: localStorage.getItem('token') } };
      const datad = { answer: userAnswers.value.join('') };
      axios
        .post(`/api/tests/${route.params.id}/submit`, datad, headers)
        .then(() => {
          router.push(`/tests/${route.params.id}/results/`);
        })
        .catch(() => {
          Swal.fire({
            title: 'Something went wrong!',
            text: 'Please try again!',
            icon: 'error',
            showCancelButton: true,
            confirmButtonText: 'Retry',
            confirmButtonColor: '#007BFF',
            cancelButtonText: 'Results',
            cancelButtonColor: '#28A745',
          }).then((result) => {
            if (result.isConfirmed) {
              submit();
            } else {
              router.push(`/tests/${route.params.id}/results`);
            }
          });
        });
    };
    const next = () => {
      now.value = Math.floor(((id.value + 1) * 100) / userAnswers.value.length);
      userAnswers.value[current.value.id] = answer.value;
      id.value += 1;
      answer.value = '';
      if (id.value === data.questions.length) {
        submit();
        status.value = 0;
      } else {
        current.value.id = data.questions[id.value].id;
        current.value.question = data.questions[id.value].body;
        current.value.options = data.questions[id.value].options;
        current.value.score = data.questions[id.value].score;
        current.value.valid = data.questions[id.value].valid;
      }
    };
    const shuffle = (x) => {
      const r = x;
      for (let i = x.length - 1; i > 0; i -= 1) {
        const j = Math.floor(Math.random() * (i + 1));
        [r[i], r[j]] = [r[j], r[i]];
      }
      return x;
    };
    // eslint-disable-next-line
    const fp1 = (x) => {
      // eslint-disable-next-line
      const y = x.map((row, id) => {
        // eslint-disable-next-line
        return { id: id, value: row };
      });
      return y;
    };
    const loadData = () => {
      axios
        .get(`/api/tests/${route.params.id}/start`, {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          const testOptions = JSON.parse(response.data.test.config);
          const { question, test } = response.data;

          if (question.length === 0) {
            document.write();
            return;
          }
          // eslint-disable-next-line
          let x = question.map((row, idx) => {
            return {
              id: idx,
              body: row.body,
              options: !testOptions.shuffleOptions
                ? fp1(JSON.parse(row.options))
                : shuffle(fp1(JSON.parse(row.options))),
              score: row.score,
              valid: row.valid,
            };
          });
          if (testOptions.shuffleQuizes) {
            x = shuffle(x);
          }
          data.test = test;
          data.questions = x;
          data.testOptions = testOptions;
          current.value.id = data.questions[0].id;
          current.value.question = data.questions[0].body;
          current.value.options = data.questions[0].options;
          current.value.score = data.questions[0].score;
          current.value.valid = data.questions[0].valid;
          userAnswers.value = Array.from({ length: x.length }).fill('R');
          if (data.test.finish - data.test.duration < Math.floor(new Date() / 1000)) {
            interval.time = data.test.finish - data.test.duration;
          } else {
            interval.time = data.test.duration;
          }
          const s2hms = (_) => {
            const date = new Date(null);
            date.setSeconds(_);
            const result = date.toISOString().slice(11, 19);
            return result;
          };
          interval.display = s2hms(interval.time);
          interval.value = setInterval(() => {
            interval.time -= 1;

            interval.display = s2hms(interval.time);
            if (interval.time === 0 || interval.time < 0) {
              submit();
              clearInterval(interval.value);
            }
          }, 1000);
          status.value = 1;
        })
        .catch(() => {
          Swal.fire({
            title: 'Something went wrong!',
            text: 'Please try again!',
            icon: 'error',
            showCancelButton: true,
            confirmButtonText: 'Retry',
            confirmButtonColor: '#007BFF',
            cancelButtonText: 'Results',
            cancelButtonColor: '#28A745',
          }).then((result) => {
            if (result.isConfirmed) {
              loadData();
            } else {
              router.push(`/tests/${route.params.id}/results`);
            }
          });
        });
    };
    onMounted(() => {
      loadData();
    });
    return {
      now,
      current,
      id,
      data,
      router,
      answer,
      userAnswers,
      next,
      submit,
      interval,
      status,
    };
  },
};
</script>
