<template>
  <Sidenav :active="1" />
  <div class="container-fluid main-content mt-3">
    <div v-if="data !== 'no'">
      <h1 class="y">List off all open-source tests</h1>
      <div class="table-responsive">
        <table class="table table-hover table-bordered table-sm" style="max-width: 1200px">
          <thead>
            <tr>
              <th scope="col" class="fs-bold">#</th>
              <th scope="col" class="fs-bold">Title</th>
              <th scope="col" class="fs-bold">Tests</th>
              <th scope="col" class="fs-bold">Creator</th>
              <th scope="col" class="fs-bold">Start time</th>
              <th scope="col" class="fs-bold">Duration</th>
              <th scope="col" class="fs-bold">Status</th>
              <th socpe="col" class="fs-bold">Start</th>
              <th socpe="col" class="fs-bold">Results</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, id) in data" :key="id">
              <td>{{ id + 1 }}</td>
              <td>
                {{ row.title }}
              </td>
              <td>{{ row.tests }}</td>
              <td>
                <RouterLink :to="'/profile/' + row.author">{{
                  fullName(row['first_name'], row['last_name'])
                }}</RouterLink>
              </td>
              <td>{{ formatTime(row.start) }}</td>
              <td>{{ s2HM(row.duration) }}</td>
              <td>
                <span class="badge" :class="getStatus(row)[1]">{{ getStatus(row)[0] }}</span>
              </td>
              <td>
                <MDBBtn color="primary" floating @click="start(row.id)">
                  <i class="fa fa-arrow-right"></i>
                </MDBBtn>
              </td>
              <td class="text-center">
                <MDBBtn color="success" floating @click="results(row.id)">
                  <i class="fa-solid fa-chart-simple"></i>
                </MDBBtn>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else>
      <Spinner />
    </div>
  </div>
</template>
<style scoped>
.y::before {
  background-color: black;
  width: 5px;
  height: 100px;
}
.table {
  border-radius: 50px !important;
}
.fs-bold {
  font-weight: 500 !important;
  font-size: 20px;
}
</style>

<script>
import { MDBBtn } from 'mdb-vue-ui-kit';
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from '../axios';
import Sidenav from '../components/NewSidenav.vue';
import Spinner from '../components/MySpinner.vue';

export default {
  components: {
    Sidenav,
    MDBBtn,
    Spinner,
  },
  setup() {
    const router = useRouter();
    const data = ref('no');
    const sidenav = ref(false);
    const start = (i) => {
      router.push(`tests/${i}`);
    };
    onMounted(() => {
      axios
        .get('/api/tests', {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        })
        .then((r) => {
          data.value = r.data;
        })
        .catch(() => {
          data.value = 'no';
        });
    });
    const formatTime = (unixTimestamp) => {
      const date = new Date(unixTimestamp * 1000);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = String(date.getFullYear()).padStart(2, '0');
      const hour = String(date.getHours()).padStart(2, '0');
      const minute = String(date.getMinutes()).padStart(2, '0');
      // eslint-disable-next-line
      return day + ':' + month + ':' + year + ' ' + hour + ':' + minute;
    };
    const s2HM = (x) => {
      const hours = Math.floor(x / 3600);
      const minutes = Math.floor((x % 3600) / 60);
      return `${hours}:${minutes}`;
    };
    // eslint-disable-next-line
    const fullName = (x, y) => y + ' ' + x;
    // eslint-disable-next-line
    const getStatus = (x) => {
      if (x.start > Math.floor(Date.now() / 1000)) {
        return ['Not started', 'badge-success'];
      }
      if (x.start < Math.floor(Date.now() / 1000) && x.finish > Math.floor(Date.now() / 1000)) {
        return ['In progress', 'badge-primary'];
      }
      return ['Finished', 'badge-danger'];
    };
    return {
      data,
      formatTime,
      getStatus,
      s2HM,
      fullName,
      sidenav,
      start,
      results: (i) => {
        router.push(`/tests/${i}/results`);
      },
    };
  },
};
</script>
